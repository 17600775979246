// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Not applied by default but can be used to change default styling if needed */
.light {
  --ion-item-background: rgb(255, 255, 255);
  --ion-item-background-half: rgba(255, 255, 255, 0);
}

:root {
  --swiper-pagination-bottom: 60px;
  --swiper-pagination-bullet-inactive-color: var(--ion-text-color, grey);

  .ios {
    --swiper-pagination-bottom: 100px;
  }

  .reorder-icon {
    opacity: 1;
    color: var(--ion-color-primary);
    font-size: 31px;
  }
}

ion-popover {
  --width: fit-content;
}

ion-router-outlet > .ion-page {
  animation: fadeIn 0.3s 1 forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ios {
  --ion-background-color: white;
}

.md .toast-offset {
  transform: translateY(-56px) !important;
}
.ios .toast-offset {
  transform: translateY(-50px) !important;
}

.html {
  background-color: black;
}

div.alert-checkbox-label {
  white-space: pre-wrap !important;
}

.ios {
  ion-checkbox {
    margin-right: 6px;
  }
}

.alert-wrapper.sc-ion-alert-md {
  max-width: 80%;
}

.alert-wrapper.sc-ion-alert-ios {
  max-width: 80%;
}

@media only screen and (min-width: 500px) {
  .alert-wrapper.sc-ion-alert-md {
    max-width: 400px;
  }

  .alert-wrapper.sc-ion-alert-ios {
    max-width: 400px;
  }
}
/* 
super-tabs-toolbar {
  background-color: rgb(250, 250, 250);
  --st-indicator-color: #545454;
} */

.tab-disabled {
  opacity: 1;
}

.amoled {
  /*   super-tabs-toolbar {
    background-color: var(--ion-background-color);
  } */

  /*   --ion-asterisk-color: #ff4343; */

  --ion-input-background: #171717;
  .ios {
    --ion-background-color: #000000;
  }
  ion-checkbox {
    border-color: #545454 !important;
    --border-color: #545454;
  }
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #d3d3d3;
  --ion-text-color-rgb: 211, 211, 211;
  --ion-table-header: #060606;

  --ion-color-step-50: #0b0b0b;
  --ion-color-step-100: #0a0a0a;
  --ion-color-step-150: #202020;
  --ion-color-step-200: #2a2a2a;
  --ion-color-step-250: #353535;
  --ion-color-step-300: #3f3f3f;
  --ion-color-step-350: #4a4a4a;
  --ion-color-step-400: #545454;
  --ion-color-step-450: #5f5f5f;
  --ion-color-step-500: #6a6a6a;
  --ion-color-step-550: #747474;
  --ion-color-step-600: #7f7f7f;
  --ion-color-step-650: #898989;
  --ion-color-step-700: #949494;
  --ion-color-step-750: #9e9e9e;
  --ion-color-step-800: #a9a9a9;
  --ion-color-step-850: #b3b3b3;
  --ion-color-step-900: #bebebe;
  --ion-color-step-950: #c8c8c8;
  --highlight-background: #3880ff;

  --ion-item-background: rgb(11, 11, 11);
  --ion-item-background-half: rgba(11, 11, 11, 0);

  ion-card {
    border: 1px solid #1f1f1f;
  }

  div.alert-wrapper {
    background-color: #000000 !important;
  }

  .alert-message.sc-ion-alert-ios,
  .alert-input-group.sc-ion-alert-ios {
    color: #d3d3d3 !important;
  }
}

.pizza-time {
  ion-content {
    --background: linear-gradient(to right, rgba(255, 255, 255, 0.884) 0 100%),
      url("assets/img/pizza-tile.png");
  }
}

.pizza-time.dark {
  ion-content {
    --background: linear-gradient(to right, rgba(42, 42, 42, 0.486) 0 100%),
      url("assets/img/pizza-tile.png") !important;
  }
}

.pizza-time.amoled {
  ion-content {
    --background: linear-gradient(to right, rgba(0, 0, 0, 0.89) 0 100%),
      url("assets/img/pizza-tile-2.png") !important;
  }
}

.dark {
  /*   super-tabs-toolbar {
    background-color: var(--ion-background-color);
  } */

  /*   --ion-asterisk-color: #d33030; */

  --ion-input-background: #2d2d2d;
  .ios {
    --ion-background-color: #2a2a2a;
  }
  ion-checkbox {
    border-color: #717171 !important;
    --border-color: #717171;
  }

  --highlight-background: #3880ff;

  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-table-header: #1e1e1e;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: rgb(30, 30, 30);
  --ion-item-background-half: rgba(30, 30, 30, 0);

  --ion-toolbar-background: #131313;

  --ion-tab-bar-background: #131313;

  --ion-card-background: #1e1e1e;

  div.alert-wrapper {
    background-color: #121212 !important;
  }

  .alert-message.sc-ion-alert-ios,
  .alert-input-group.sc-ion-alert-ios {
    color: #d3d3d3 !important;
  }
}
.popover-content.sc-ion-popover-md,
.popover-content.sc-ion-popover-ios {
  width: unset;

  .popover-content.sc-ion-popover-md,
  .popover-content.sc-ion-popover-ios {
    right: 12px;
  }
}

.alert-message.sc-ion-alert-md,
.alert-message.sc-ion-alert-ios {
  max-height: 90%;
  mask-image: linear-gradient(to bottom, black 97%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, black 97%, transparent 100%);
}

.div.alert-button-group.sc-ion-alert-md,
.div.alert-button-group.sc-ion-alert-ios {
  padding-top: 0;
  height: 30px;
}

.tooltip-inner-title {
  font-size: 22px;
}

.tooltip-list {
  padding-left: 16px;
  li {
    margin-bottom: 20px;
  }
}

.title-heading {
  text-align: left;
  padding-left: 20px;
}

.alert-message.sc-ion-alert-ios,
.alert-input-group.sc-ion-alert-ios {
  text-align: left !important;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

.pizza-time {
  --swiper-pagination-color: #ff5100;

  --ion-color-primary: #ff5100;
  --ion-color-primary-rgb: 255, 81, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;

  --ion-color-primary-shade: #e04700;
  --ion-color-primary-tint: #ff621a;
}

ion-modal#review-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#review-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#review-modal ion-icon {
  margin-right: 6px;

  width: 48px;
  height: 48px;

  padding: 4px 0;

  color: #aaaaaa;
}

ion-modal#review-modal .wrapper {
  margin-bottom: 10px;
}

.pagebreak {
  clear: both;
  page-break-after: always;
}

ion-toolbar {
  height: var(--header-height);

  transition: height 0.3s ease-out;
}
